<template>
  <div class="bg-black">
    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 py-3 relative">
      <a
        href="https://www.essex.gov.uk/"
        target="_blank"
        class="text-sm text-white underline decoration-1 underline-offset-2 hover:decoration-2 focus:outline-none focus:text-black focus:bg-yellow-500 focus:decoration-2"
      >
        &copy; Essex County Council {{ new Date().getFullYear() }}
      </a>
    </div>
  </div>
</template>
