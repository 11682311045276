<template>
  <div class="flex">
    <img
      src="/images/logo.png"
      srcset="/images/logo@2x.png 2x"
      alt="Image of the TravelEssex Logo"
      class="max-lg:hidden"
    />

    <img
      src="/images/mobile-logo.png"
      srcset="/images/mobile-logo@2x.png 2x"
      alt="Image of the TravelEssex Logo"
      class="lg:hidden"
    />
  </div>
</template>
