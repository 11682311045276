<script setup>
import ECCLogo from '@/Shared/Components/ECCLogo.vue'
import AppCopyrightFooter from '@/Shared/Components/AppCopyrightFooter.vue'
import AppLink from '@/Shared/Components/Links/AppLink.vue'

const links = [
  { href: 'http://travelessex.co.uk/', text: 'About TravelEssex.gov.uk' },
  { href: '/accessibility-statement', text: 'Accessibility' },
  { href: '/advice/faq', text: 'FAQs' },
  { href: '/terms-conditions', text: 'Terms, conditions and disclaimer' },
  { href: '/privacy-notice', text: 'Privacy notice' },
]
</script>

<template>
  <footer class="w-100 bg-gray-200">
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 relative">
      <div class="flex flex-col lg:flex-row justify-between space-y-12 lg:space-y-0 py-8">
        <nav>
          <ul class="flex md:space-x-12 flex-col md:flex-row">
            <li
              v-for="(link, index) in links"
              :key="index"
              class="py-3 md:py-0"
            >
              <AppLink
                :href="link.href"
                target="_blank"
                class="text-sm"
                secondary
              >
                {{ link.text }}
              </AppLink>
            </li>
          </ul>
        </nav>
        <div class="lg:pl-12">
          <a
            href="https://www.essex.gov.uk/"
            target="_blank"
            class="block focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
          >
            <ECCLogo class="ml-auto" />
          </a>
        </div>
      </div>
    </div>
    <AppCopyrightFooter />
  </footer>
</template>
